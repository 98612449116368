<template>
  <screen_width_cont>
    <div class="myBodyGeneral">
      <div class="centrado" ref="myHeader_cotizador">
        <div class="cont_300_basico ancho_acotado">
          <div class="header_top">
            <headerCerrarInfoCuenta
              @callCuenta="callCuentaLocal"
              @callInfo="callInfoLocal"
              @cancelar="cancelarLocal"
            />
          </div>
        </div>
      </div>

      <blurComp
        class="blur_sup"
        :style="blur_sup_cotizador"
        :scroll_to_="scroll_to_top"
        tipo="sup"
      />

      <div
        class="contenido centrado"
        ref="cotizador_contenido"
        :style="contenido_cotizador"
        @scroll="onScroll"
      >
        <div class="cont_300_basico ancho_acotado">
          <div class="cotizadorTitle title title_margin_top">
            {{ t("app.cotizador.tit") }}
          </div>

          <div class="cotizadorFormCont">
            <div class="cotizadorFormOrden">
              <div class="cotizadorFormDesde">
                <inputSimulador
                  @selectSelected="selectSelectedLocal"
                  :selectSelectedType="selectSelectedType"
                  :label="t('app.cotizador.envias')"
                  :lista="configuracion.data.auxiliares.pais_moneda_destino_id"
                  :cerrarListaPais="cerrarListaPais"
                  id_listaPais="desde"
                  @cerrarOtraListaPais="cerrarOtraListaPaisLocal"
                  :otraListaPaisCerrar="otraListaPaisCerrar"
                  :modoPesoToDestino="modoPesoToDestino"
                  :valor="valorSimuladorOrigen"
                  :simbolMoney="
                    modoPesoToDestino
                      ? 'CLP'
                      : objTransaccionLocal.moneda_destino_sigla
                  "
                  tipo="txtSimuladorOrigen"
                  @calculateAmountL="calculateAmount"
                  :editable="refreshFocus"
                  @setFocus="cambioValorLocal"
                  @paisSelected="paisSelectedLocal"
                  @changeInputSimuladorMandante="
                    changeInputSimuladorMandanteLocal
                  "
                  :mandante="mandante"
                  :error="errorSimulador"
                  :msgError="textErrorSimulador"
                />
              </div>

              <div class="cotizadorFormHasta">
                <inputSimulador
                  @selectSelected="selectSelectedLocal"
                  :selectSelectedType="selectSelectedType"
                  :label="t('app.cotizador.dest_recibe')"
                  :lista="configuracion.data.auxiliares.pais_moneda_destino_id"
                  :cerrarListaPais="cerrarListaPais"
                  id_listaPais="hacia"
                  @cerrarOtraListaPais="cerrarOtraListaPaisLocal"
                  :otraListaPaisCerrar="otraListaPaisCerrar"
                  :modoPesoToDestino="modoPesoToDestino"
                  :valor="valorSimuladorDestino"
                  :simbolMoney="
                    modoPesoToDestino
                      ? objTransaccionLocal.moneda_destino_sigla
                      : 'CLP'
                  "
                  tipo="txtSimuladorDestino"
                  @calculateAmountL="calculateAmount"
                  :editable="true"
                  @setFocus="cambioValorLocal"
                  @paisSelected="paisSelectedLocal"
                  @changeInputSimuladorMandante="
                    changeInputSimuladorMandanteLocal
                  "
                  :mandante="mandante"
                  :paisDestinoPreSelected="paisDestinoPreSelected"
                  :error="false"
                  msgError=""
                />
              </div>
            </div>

            <div class="cotizadorFormDetalle">
              <!-- detalle -->
              <div class="cotizadorFormDetalleIcon">
                <div class="cotizadorFormDetalleIconImg">
                  <img :src="iconCotizadorDetalleGrisMenos" alt="" srcset="" />
                </div>
                <div class="cotizadorFormDetalleIconImg">
                  <img :src="iconCotizadorDetalleCupon" alt="" srcset="" />
                </div>

                <div class="cotizadorFormDetalleIconImg">
                  <img :src="iconCotizadorIgual" alt="" srcset="" />
                </div>
                <div class="cotizadorFormDetalleIconImgUltimo">
                  <img :src="iconCotizadorDivide" alt="" srcset="" />
                </div>
              </div>

              <div class="cotizadorFormDetalle_der">
                <div class="cotizadorFormDetalle_der_row">
                  <div class="cotizadorFormDetalleText">
                    <div class="cotizadorFormDetalleMonto simulador_FormDetalleMonto">
                      <div
                        class="cotizadorFormDetalleMontoRow cotizadorFormDetalleText_gris "
                      >
                        {{ format(comision) }} CLP
                      </div>
                    </div>
                    <div class="cotizadorFormDetalleTextContainer">
                      <div
                        class="cotizadorFormDetalleTextRow cotizadorFormDetalleText_gris"
                      >
                        {{ t("app.cotizador.costoEnvio") }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="cotizadorFormDetalle_der_row">
                  <div class="cotizadorFormDetalleText">
                    <div class="cotizadorFormDetalleMonto simulador_FormDetalleMonto">
                      <div
                        class="cotizadorFormDetalleMontoRow cotizadorFormDetalleMonto_lila simulador_DetalleText_monto_descuento"
                      >
                        <div v-if="descuentoExiste">
                          {{ format(descuento) }} CLP
                        </div>
                        <div
                          v-else
                          class="cotizadorFormDetalleMontoRowBtnCuponDesc"
                          @click="activarModalCupon"
                        >
                          {{ t("app.cotizador.cuponDescuento") }}
                        </div>
                      </div>
                    </div>
                    <div class="cotizadorFormDetalleTextContainer">
                      <div
                        class="cotizadorFormDetalleTextRow cotizadorFormDetalleMonto_lila"
                      >
                        <div
                          v-if="descuentoExiste"
                          class="cotizadorFormDetalleTextRowDescuento"
                        >
                          {{ t("app.cotizador.descuentoDe") }}&nbsp;
                          <span v-if="objRespCupon.tipo_descuento == '1'"
                            >{{
                              objRespCupon.porcentaje_descuento
                            }}&nbsp;%&nbsp;</span
                          >
                          <span v-if="objRespCupon.tipo_descuento == '2'"
                            >${{
                              format(objRespCupon.valor_descuento)
                            }}&nbsp;</span
                          >
                          {{ t("app.cotizador.enElCostoEnvio") }}
                          <div
                            class="cotizadorFormDetalleTextRowDescuentoBtn"
                            @click="sinDescuento"
                          >
                            <img :src="iconCerrarAzul" alt="" srcset="" />
                          </div>
                        </div>
                        <!-- <div
                          v-if="descuentoExiste"
                          class="cotizadorFormDetalleTextRowDescuento"
                        >
                          {{ t("app.cotizador.enElCostoEnvio") }}
                        </div> -->
                      </div>
                    </div>
                  </div>
                </div>

                <div class="cotizadorFormDetalle_der_row">
                  <div class="cotizadorFormDetalleText">
                    <div class="cotizadorFormDetalleMonto simulador_FormDetalleMonto">
                      <div
                        class="cotizadorFormDetalleMontoRow cotizadorFormDetalleText_gris simulador_DetalleText_monto_descuento"
                      >
                        {{ format(aConvertir) }} CLP
                      </div>
                    </div>
                    <div class="cotizadorFormDetalleTextContainer">
                      <div
                        class="cotizadorFormDetalleTextRow cotizadorFormDetalleText_gris"
                      >
                        {{ t("app.cotizador.aConvertir") }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="cotizadorFormDetalle_der_row">
                  <div class="cotizadorFormDetalleText">
                    <div class="cotizadorFormDetalleMonto simulador_FormDetalleMonto">
                      <div
                        class="cotizadorFormDetalleMontoRow cotizadorFormDetalleText_gris simulador_DetalleText_monto_tasa"
                      >
                        {{ tasa_mostrar }}
                      </div>
                    </div>
                    <div class="cotizadorFormDetalleTextContainer">
                      <div
                        class="cotizadorFormDetalleTextRow cotizadorFormDetalleText_gris"
                      >
                        {{ t("app.cotizador.tasaGarantizada") }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="cotizadorBtn_container">
            <div class="cotizadorBtn">
              <btnAction
                :textBtn="t('app.comunes.btns.continuar')"
                @action="simulator"
                colorBack="azul"
                colorText="blco"
                :estadoBtn="estadoBtn"
                :msg_completar="t('app.comunes.btns.error_montos')"
              />
            </div>

            <div class="cotizadorEtapas">
              <pasosGiro paso="1" totales="6" />
            </div>
          </div>
        </div>
      </div>

      <blurComp
        class="blur_inf"
        :style="blur_inf_cotizador"
        :scroll_to_="scroll_to_bottom"
        tipo="inf"
      />

      <div class="footer_estrecho_centrado" ref="footer_cotizador">
        <div class="footer_env_seguro">
          <enviosSeguros @callEnvioSeguroModal_="callEnvioSeguroModal" />
        </div>
      </div>
    </div>
  </screen_width_cont>
</template>

<script>
import { modalController, menuController, alertController } from "@ionic/vue";
import { useI18n } from "vue-i18n";
import headerCerrarInfoCuenta from "../components/generales/headerCerrarInfoCuenta.vue";
import cuponDescuento from "@/views/modales/cuponDescuento.vue";
import inputSimulador from "@/components/simulador/inputSimulador.vue";
import { mapActions, mapMutations, mapState } from "vuex";
import btnAction from "../components/generales/btnAction.vue";
import pasosGiro from "../components/generales/pasosProceso.vue";
import envioSeguroHome from "./modales/enviosSegurosHome.vue";
import enviosSeguros from "../components/generales/envios_seguros.vue";
import screen_width_cont from "../components/menuSp/screen_width_cont.vue";
import blurComp from "../components/generales/blur.vue";
import {
  blur_sup_scroll,
  blur_inf_scroll,
  contenido_style,
  blur_sup_style,
  blur_inf_style,
} from "../assets/funciones/blur";

export default {
  setup() {
    const { t, locale } = useI18n();
    return { t, locale };
  },
  name: "cotizador",
  components: {
    headerCerrarInfoCuenta,
    inputSimulador,
    btnAction,
    pasosGiro,
    enviosSeguros,
    screen_width_cont,
    blurComp,
  },
  data() {
    return {
      valorSimuladorOrigen: 500000,
      valorSimuladorDestino: 0,
      tipo_cambioModal: 0,
      errorSimulador: true,
      textErrorSimulador: "",
      comision: 0,
      descuento: 0,

      descuentoExiste: false,
      objRespCupon: null,
      modoPesoToDestino: true,
      enVista: true,
      primeraCarga: true,

      nuevoMontoDepositar: "",
      refreshFocus: true,
      cerrarListaPais: false,
      otraListaPaisCerrar: "",
      aConvertir: 0,
      selectSelectedType: "",
      iconCotizadorDetalleGrisMenos: require("../assets/imgs/cotizador/iconCotizadorDetalleGrisMenos.svg"),
      iconCotizadorDetalleCupon: require("../assets/imgs/cotizador/iconCotizadorDetalleAzul.svg"),
      iconCotizadorIgual: require("../assets/imgs/cotizador/iconCotizadorDetalleIgual.svg"),
      iconCotizadorDivide: require("../assets/imgs/cotizador/iconCotizadorDetalleDivide.svg"),
      iconCotizadorSendBlue: require("../assets/imgs/iconCotizadorSendBlue.svg"),
      iconCerrarAzul: require("../assets/imgs/iconCerrarAzul.svg"),
      mandante: "desde",
      paisDestinoPreSelected: "",
      btnActive: true,
      header: 0,
      footer: 0,
      scroll_to_bottom: true,
      scroll_to_top: true,
      contenido_cotizador: {},
      blur_sup_cotizador: {},
      blur_inf_cotizador: {},
    };
  },
  computed: {
    ...mapState([
      "transaccion",
      "costos_pais",
      "remitente",
      "session_confirm",
      "configuracion",
      "resumen",
      "cardBodyPaises",
      "paises",
      "desde",
      "nav_stack",
      "widthScreen",
      "monto_inicio",
      "pais_destino_inicial",
      "monto_inicio_BD",
      "pais_destino_inicial_BD",
      "heigthScreen",
      "plataforma",
      "i18n_locales",
    ]),
    tituloReact() {
      let aux = "Calcula tu envío";

      return aux;
    },
    objTransaccionLocal() {
      let aux = {
        giro_gastos_envio: 0,
        valor_cambio: 0,
        monto_origen: 0,
        tasa_invertida: "",
        monto_destino: 0,
        moneda_origen_sigla: "",
        moneda_destino_sigla: "",
        valor_minimo_origen: 0,
        valor_maximo_origen: 0,
        monto_pagar: 0,
        tasaSimbolA: "",
        tasaSimbolB: "",
        tasa: 0,
        moneda_destino_id: "",
      };

      if (this.transaccion.giro_gastos_envio)
        aux.giro_gastos_envio = this.transaccion.giro_gastos_envio;
      if (this.transaccion.valor_cambio)
        aux.valor_cambio = this.transaccion.valor_cambio;
      if (this.transaccion.monto_origen)
        aux.monto_origen = this.transaccion.monto_origen;
      if (this.transaccion.tasa_invertida)
        aux.tasa_invertida = this.transaccion.tasa_invertida;
      if (this.transaccion.monto_destino)
        aux.monto_destino = this.transaccion.monto_destino;
      if (this.transaccion.moneda_origen_sigla)
        aux.moneda_origen_sigla = this.transaccion.moneda_origen_sigla;
      if (this.transaccion.moneda_destino_sigla)
        aux.moneda_destino_sigla = this.transaccion.moneda_destino_sigla;
      if (this.transaccion.valor_minimo_origen)
        aux.valor_minimo_origen = this.transaccion.valor_minimo_origen;
      if (this.transaccion.valor_maximo_origen)
        aux.valor_maximo_origen = this.transaccion.valor_maximo_origen;
      if (this.transaccion.monto_pagar)
        aux.monto_pagar = this.transaccion.monto_pagar;
      if (this.transaccion.moneda_destino_id) {
        aux.moneda_destino_id = this.transaccion.moneda_destino_id;
      }

      if (this.transaccion.tasa_invertida == "1") {
        aux.tasaSimbolA = this.transaccion.moneda_origen_sigla;
        aux.tasaSimbolB = this.transaccion.moneda_destino_sigla;
        aux.tasa = parseFloat(1 / this.transaccion.valor_cambio)
          .toFixed(2)
          .replace(".", ",");
      } else {
        aux.tasaSimbolA = this.transaccion.moneda_destino_sigla;
        aux.tasaSimbolB = this.transaccion.moneda_origen_sigla;

        aux.tasa = this.transaccion.valor_cambio;
      }
      // console.log(aux.tasa);
      return aux;
    },
    tiempoEntregaNumber() {
      let aux = "1";

      return aux;
    },
    estadoBtn() {
      let aux = "inactive";
      if (!this.errorSimulador) {
        aux = "active";
      }

      return aux;
    },
    tasa_mostrar() {
      let aux = "";
      if (this.objTransaccionLocal.tasa && this.transaccion.moneda_destino_id) {
        if (this.objTransaccionLocal.tasa < 1) {
          let decimales_index = this.configuracion.monedas.findIndex(
            (moneda) => {
              return moneda.id == this.transaccion.moneda_destino_id;
            }
          );
          let decimales = 0;
          if (decimales_index > -1) {
            decimales =
              this.configuracion.monedas[decimales_index].cantidad_decimales;
          }
          let tasa_calc = 1 / this.objTransaccionLocal.tasa;
          tasa_calc = parseFloat(tasa_calc.toFixed(decimales));
          tasa_calc = tasa_calc.toLocaleString(
            `${this.locale}-${this.locale.toUpperCase()}`
          );

          aux = `1 ${this.objTransaccionLocal.moneda_destino_sigla} = ${tasa_calc} ${this.objTransaccionLocal.moneda_origen_sigla}`;
        } else {
          let decimales_index = this.configuracion.monedas.findIndex(
            (moneda) => {
              return moneda.id == this.transaccion.moneda_destino_id;
            }
          );
          let decimales = 0;
          if (decimales_index > -1) {
            decimales =
              this.configuracion.monedas[decimales_index].cantidad_decimales;
          }
          let tasa_calc = parseFloat(
            this.objTransaccionLocal.tasa.toFixed(decimales)
          );
          tasa_calc = tasa_calc.toLocaleString(
            `${this.locale}-${this.locale.toUpperCase()}`
          );
          // console.log(tasa_calc);

          aux = `1 ${this.objTransaccionLocal.moneda_origen_sigla} = ${tasa_calc} ${this.objTransaccionLocal.moneda_destino_sigla}`;
        }
      }
      // console.log(aux);
      return aux;
    },
  },
  methods: {
    ...mapActions([
      "validacionesAux",
      "limpiarTransaccion",
      "validarDestinoSelected",
      "calcular_monto_origen",
      "getShippingCost",
      "getShippingCost_factor",
      "check_getShippingCost",
      "valida_monthly_amount",
      // "sendFacebookConversion",
    ]),
    ...mapMutations([
      "updateTransaccion",
      "eliminarTransaccionPaisDestino",
      "updateDesde",
      "updateBloquearNavegacion",
      "updateProcesoGeneral",
      "updateMenuSpDesde",
      "set_nav_stack",
      "setshowSpinner",
      "set_pre_values_cotizador",
      "reset_transaccion",
    ]),

    callCuentaLocal() {
      this.updateMenuSpDesde({ desde: "cotizador", menuSp: true });
      menuController.open("firstMenu");
    },
    callInfoLocal() {
      this.updateMenuSpDesde({ desde: "cotizador", menuSp: true });

      this.set_nav_stack({
        desde: ["cotizador"],
        menuSp: this.nav_stack.menuSp,
      });

      this.$router.push({
        name: "centroAyuda",
        params: { tema: "0" },
        replace: true,
      });
    },
    cancelarLocal() {
      this.enVista = false;
      // this.updateTransaccion({
      //   moneda_destino_sigla: "",
      //   banco: "",
      //   id_banco_destino: "",
      //   tipo_cuenta: "",
      //   cuenta_corriente: "",
      //   motivo_transaccion: "",
      //   numero_cuenta: "",
      //   id_persona: "",
      //   forma_entrega_id: "",
      //   tasa_mostrar: "",
      //   detalle_entrega_id: "",
      //   monto_origen: 0,
      //   monto_pagar: 0,
      //   monto_destino: 0,
      //   pais_destino: null,
      // });
      this.reset_transaccion();
      this.set_pre_values_cotizador({
        monto_inicio: this.monto_inicio_BD,
        pais_destino_inicial: this.pais_destino_inicial_BD,
      });
      this.$router.push({ name: "home", replace: true });
    },

    selectSelectedLocal(selectSelectedType) {
      this.selectSelectedType = selectSelectedType;
    },

    validarErroresMonto() {
      this.textErrorSimulador = ``;
      this.errorSimulador = false;
      let simulador_origen;

      simulador_origen = this.valorSimuladorOrigen;
      if (typeof this.valorSimuladorOrigen == "string") {
        simulador_origen = this.unformat(this.valorSimuladorOrigen);
      }

      if (isNaN(simulador_origen)) {
        this.errorSimulador = true;
        this.textErrorSimulador = `cantidad a enviar?`;

        return false;
      }

      if (simulador_origen < this.objTransaccionLocal.valor_minimo_origen) {
        this.errorSimulador = true;
        this.textErrorSimulador = `Depósito minimo de ${this.format(
          parseInt(this.objTransaccionLocal.valor_minimo_origen)
        )}`;

        return false;
      }

      if (simulador_origen > this.objTransaccionLocal.valor_maximo_origen) {
        this.errorSimulador = true;
        this.textErrorSimulador = `Depósito maximo de ${this.format(
          parseInt(this.objTransaccionLocal.valor_maximo_origen)
        )}`;

        return false;
      }

      return true;
    },

    async simulator() {
      this.setshowSpinner(false);
      this.textErrorSimulador = ``;
      let simulador_origen = this.unformat(this.valorSimuladorOrigen);
      let simulador_destino = this.unformat(this.valorSimuladorDestino);

      // this.sendFacebookConversion({
      //         pixel_id:"779948816213529",
      //         event_name: 'cotizador',
      //         monto_origen: simulador_origen
      //       });

      if (!isNaN(simulador_origen) && !isNaN(simulador_destino)) {
        if (simulador_origen >= this.objTransaccionLocal.valor_minimo_origen) {
          if (
            simulador_origen <= this.objTransaccionLocal.valor_maximo_origen
          ) {
            this.errorSimulador = false;
            this.updateTransaccion({ monto_pagar: simulador_origen });

            if (typeof this.remitente.id == "undefined") {
              return false;
            }
            if (!(await this.validMonthlyAmount(simulador_origen))) {
              return;
            }
            if (this.objRespCupon == null) {
              this.objRespCupon = {
                tipo_descuento: "",
                porcentaje_descuento: 0,
                valor_descuento: 0,
                id: null,
                cupon: null,
                tasa_mostrar: this.tasa_mostrar,
              };
            } else {
              // console.log("**",this.objRespCupon);
              this.objRespCupon.descuento = this.descuento;
              this.objRespCupon.tasa_mostrar = this.tasa_mostrar;
            }
            this.objRespCupon.conservar_destinatario = false;
            await this.validacionesAux(this.objRespCupon);
          } else {
            this.errorSimulador = true;
            this.textErrorSimulador = `Depósito maximo de ${this.format(
              parseInt(this.objTransaccionLocal.valor_maximo_origen)
            )}`;
            return false;
          }
        } else {
          this.errorSimulador = true;
          this.textErrorSimulador = `Depósito minimo de ${this.format(
            parseInt(this.objTransaccionLocal.valor_minimo_origen)
          )}`;
          return false;
        }
      } else {
        return false;
      }

      if (this.session_confirm > 0) {
        this.$router.push({ name: "cotizadorDestinatario", replace: true });
      }
    },

    async calculateAmount(data) {
      
      let costo_servicio_encontrado = false;
      let datos_calc_orig = {};

      let id = data.tipo;
      if (typeof data.valor != "undefined") {
        if (data.tipo == "txtSimuladorOrigen") {
          this.valorSimuladorOrigen = data.valor;
        } else {
          this.valorSimuladorDestino = data.valor;
        }
      }
      // console.log("/////", this.valorSimuladorOrigen);
      let destino = 0;
      let origen_temp = 0;

      let valor;
      let objetoTransaccionTemp = {};
      switch (id) {
        case "txtSimuladorOrigen":
        console.log("origen", this.valorSimuladorOrigen, typeof this.valorSimuladorOrigen);
          
          if (typeof this.valorSimuladorOrigen == "string") {
            valor = this.valorSimuladorOrigen.replaceAll(
              this.i18n_locales[this.locale].miles_str,
              ""
            );
            valor = parseFloat(valor);
            console.log(valor);
          } else {
            valor = this.valorSimuladorOrigen;
          }
          origen_temp = valor;
          costo_servicio_encontrado = await this.check_getShippingCost(valor);

          if (!costo_servicio_encontrado) {
            this.comision = 0;
            this.aConvertir = 0;
            this.valorSimuladorDestino = 0;
            objetoTransaccionTemp.monto_destino = "0";
            objetoTransaccionTemp.monto_origen = "0";
            this.valorSimuladorOrigen = this.format(valor);
            break;
          }
          await this.getShippingCost(valor);

          this.comision = parseFloat(
            this.objTransaccionLocal.giro_gastos_envio
          );

          if (this.objRespCupon) {
            if (this.objRespCupon.tipo_descuento == "1") {
              this.descuento = parseInt(
                this.comision * (this.objRespCupon.porcentaje_descuento / 100)
              );
            } else if (this.objRespCupon.tipo_descuento == "2") {
              if (this.comision > this.objRespCupon.valor_descuento) {
                this.descuento = this.objRespCupon.valor_descuento;
              } else {
                this.descuento = this.comision;
              }
            }
          } else {
            this.descuento = 0;
          }

          valor =
            valor -
            (this.objTransaccionLocal.giro_gastos_envio - this.descuento);
          this.aConvertir = valor;

          if (this.objTransaccionLocal.moneda_destino_id == "2") {
            objetoTransaccionTemp.monto_destino =
              Math.floor(valor * this.objTransaccionLocal.valor_cambio * 100) /
              100;
            valor = this.format_destin_dolar(
              valor * this.objTransaccionLocal.valor_cambio
            );
          } else {
            objetoTransaccionTemp.monto_destino =
              valor * this.objTransaccionLocal.valor_cambio;
            valor = this.format(
              Math.floor(
                parseInt(valor * this.objTransaccionLocal.valor_cambio)
              )
            );
          }

          this.valorSimuladorDestino = valor;
          this.valorSimuladorOrigen = this.format(origen_temp);

          objetoTransaccionTemp.monto_origen = this.valorSimuladorOrigen;

          break;

        case "txtSimuladorDestino":
          console.log("destino", this.valorSimuladorDestino, typeof this.valorSimuladorDestino);
          if (typeof this.valorSimuladorDestino == "string") {
            destino = this.valorSimuladorDestino;
            // console.log("1->", destino);
            let aux = destino.split(this.i18n_locales[this.locale].deci_str);
            if (aux[1]) {
              let aux2 = 1;
              if (aux[1].length > 1) aux2 = 2;
              destino = `${aux[0]}${
                this.i18n_locales[this.locale].deci_str
              }${aux[1].substring(0, aux2)}`;
            }
            // console.log("2->", destino);
            destino = destino.replaceAll(
              this.i18n_locales[this.locale].miles_str,
              ""
            );
            destino = destino.replaceAll(
              this.i18n_locales[this.locale].deci_str,
              "."
            );
          } else {
            destino = this.valorSimuladorDestino;
          }
          // console.log("3->", destino);
          datos_calc_orig = await this.calcular_monto_origen({
            destino: destino,
            objRespCupon: this.objRespCupon,
            valor_cambio: this.objTransaccionLocal.valor_cambio,
          });
          this.descuento = this.format(datos_calc_orig.descuento);
          this.aConvertir = datos_calc_orig.aConvertir;
          origen_temp = datos_calc_orig.origen_temp;
          this.comision = datos_calc_orig.comision;
          this.valorSimuladorOrigen = this.format(origen_temp);
          objetoTransaccionTemp.monto_destino = destino;
          objetoTransaccionTemp.monto_origen = this.valorSimuladorOrigen;
          if (this.objTransaccionLocal.moneda_destino_id == "2") {
            this.valorSimuladorDestino = this.format_destin_dolar(destino);
          } else {
            this.valorSimuladorDestino = this.format(destino);
          }

          break;
      }

      this.updateTransaccion(objetoTransaccionTemp);
      // console.log(this.transaccion);
    },
    unformat(input) {
      if (typeof input == "object") {
        //
      } else {
        if (typeof input != "string") return;
        let str = input.split(".").join("");
        str = parseInt(str);
        return str;
      }
    },
    format_destin_dolar(destino) {
      
      let decimales_index = this.configuracion.monedas.findIndex((moneda) => {
        return moneda.id == this.transaccion.moneda_destino_id;
      });
      let decimales = 0;
      if (decimales_index > -1) {
        decimales =
          this.configuracion.monedas[decimales_index].cantidad_decimales;
      }

      let aux = Math.pow(10, decimales);

      if (typeof destino == "string") {
        let aux3 = destino.split(".");
        let aux2 = ``;
        if (decimales == 1) {
          if (!aux3[1] || aux3[1].length == 0) {
            aux2 = `${aux3[0]}0.0`;
          } else if (aux3[1] && aux3[1].length == 1) {
            aux2 = `${aux3[0]}.${aux3[1]}`;
          } else if (aux3[1] && aux3[1].length == 2) {
            aux2 = `${aux3[0]}${aux3[1].substring(0, 1)}.${aux3[1].substring(
              1,
              1
            )}`;
          }
        } else if (decimales == 2) {
          if (!aux3[1] || aux3[1].length == 0) {
            aux2 = `${aux3[0]}00.0`;
          } else if (aux3[1] && aux3[1].length == 1) {
            aux2 = `${aux3[0]}${aux3[1]}0.0`;
          } else if (aux3[1] && aux3[1].length == 2) {
            aux2 = `${aux3[0]}${aux3[1]}.0`;
          }
        }

        destino = Math.floor(aux2) / aux;
      } else {
        let aux2 = destino;
        for (let index = 0; index < decimales; index++) {
          aux2 = aux2 * 10;
        }
        destino = Math.floor(aux2) / aux;
      }
      
      destino = parseFloat(destino);

      destino = destino.toLocaleString(
        `${this.locale}${this.i18n_locales[this.locale].locales}`
      );

      return destino;
    },
    format(input) {
      // console.log(input);   
      if (typeof input == "object") {
        //
      } else if (typeof input != "undefined") {
        let str = input.toString();
        str = str
          .replace(/\D/g, "")
          .replace(/([0-9])([0-9]{3})$/, "$1.$2")
          .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
        // str = str
        //   .replace(/\D/g, "")
        //   .replace(/([0-9])([0-9]{3})$/, `$1${this.i18n_locales[this.locale].miles_str}$2`)
        //   .replace(/\B(?=(\d{3})+(?!\d)\.?)/g, this.i18n_locales[this.locale].miles_str);
        return str;
      } else {
        return input;
      }
    },

    formatearVariable(variable, tipo_formato) {
      let tempVal = String(variable).replace(/\D/g, "");
      let tempValgr = "";
      switch (tipo_formato) {
        case "telefono":
          if (tempVal.length > 0) {
            tempValgr = tempVal.replace(
              /([0-9]{0,3})([0-9]{0,4})([0-9]{0,4})/,
              "$1 $2 $3"
            );
          }
          return "+" + tempValgr;
        case "monto":
          return new Intl.NumberFormat("cl-ES").format(tempVal);
        case "cuentacorriente":
          return tempVal;
        case "numero_documento":
          return tempVal;
        default:
          return tempVal;
      }
    },
    round(num, decimales = 2) {
      let signo = num >= 0 ? 1 : -1;
      num = num * signo;
      if (decimales === 0) return signo * Math.round(num);

      num = num.toString().split("e");
      num = Math.round(
        +(num[0] + "e" + (num[1] ? +num[1] + decimales : decimales))
      );

      num = num.toString().split("e");
      return (
        signo * (num[0] + "e" + (num[1] ? +num[1] - decimales : -decimales))
      );
    },
    cerrarModal() {
      this.modalMostrar = false;
    },

    async validMonthlyAmount(simulador_origen) {
      // console.log(this.objTransaccionLocal, this.transaccion);
      let resp_valida_monto = await this.valida_monthly_amount(
        simulador_origen
      );

      if (resp_valida_monto.resp) {
        return true;
      } else {
        let mensaje = "";
        if (resp_valida_monto.cod == "31") {
          if (
            resp_valida_monto.max >=
            this.objTransaccionLocal.valor_minimo_origen
          ) {
            mensaje = `${this.t(
              "app.cotizador.monto_max_mensual_disp"
            )} $${this.format(resp_valida_monto.max)}`;
          } else {
            mensaje = `${this.t("app.cotizador.monto_max_mensual_alcanzado")}`;
          }
        } else if (resp_valida_monto.cod == "32") {
          mensaje = `${this.t(
            "app.cotizador.monto_max_diario_alcanzado"
          )}. ${this.t("app.cotizador.monto_max_diario_disp")} $${this.format(
            resp_valida_monto.max
          )}`;
        } else if (resp_valida_monto.cod == "33") {
          let aux = resp_valida_monto.max.split(".")[0];
          mensaje = `${this.t("app.cotizador.monto_max_giro")} ${this.format(
            aux
          )}`;
        }
        let alert = await alertController.create({
          header: this.t("app.logIn.error"),
          subHeader: mensaje,
          buttons: ["Ok"],
          backdropDismiss: false,
        });
        await alert.present();

        return false;
      }
    },

    async activarModalCupon() {
      this.objRespCupon = null;
      this.updateBloquearNavegacion(true);
      const modal = await modalController.create({
        component: cuponDescuento,
      });

      modal.onDidDismiss().then((codigo) => {
        this.updateBloquearNavegacion(false);
        // console.log(codigo);
        if (codigo.data.codigo) {
          this.descuentoExiste = true;
          this.objRespCupon = codigo.data.codigo;
        } else {
          this.descuentoExiste = false;
          this.objRespCupon = null;
        }
        this.calculateAmount({
          tipo: this.modoPesoToDestino
            ? "txtSimuladorOrigen"
            : "txtSimuladorDestino",
        });
      });

      return modal.present();
    },
    sinDescuento() {
      this.descuentoExiste = false;
      this.objRespCupon = null;

      this.calculateAmount({
        tipo: this.modoPesoToDestino
          ? "txtSimuladorOrigen"
          : "txtSimuladorDestino",
      });
      this.updateTransaccion({
        codigo_id: "",
        cupon: "",
      });
    },
    cambioValorLocal(valor) {
      if (!this.primeraCarga) {
        if (valor == 1) {
          this.modoPesoToDestino = true;
          this.calculateAmount({ tipo: "txtSimuladorOrigen" });
        } else {
          this.modoPesoToDestino = false;
          this.calculateAmount({ tipo: "txtSimuladorDestino" });
        }
      }
    },

    ajustarValor() {
      this.calculateAmount({ tipo: "txtSimuladorDestino" });
    },
    cerrarOtraListaPaisLocal(origen) {
      this.otraListaPaisCerrar = origen;
    },
    async paisSelectedLocal(data) {
      if (!this.primeraCarga) {
        this.descuentoExiste = false;
        this.objRespCupon = null;
      }

      let tasa_index = this.configuracion.tasasCambio.findIndex((tasa) => {
        return (
          tasa.id_pais_origen == "152_1" &&
          tasa.id_pais_destino == data.pais.pais_val
        );
      });
      // console.log(tasa_index, this.configuracion.tasasCambio);
      if (tasa_index > -1) {
        await this.updateTransaccion({
          valor_cambio: parseFloat(
            this.configuracion.tasasCambio[tasa_index].valor_cambio
          ),
          tasa_transaccion_id:
            this.configuracion.tasasCambio[tasa_index].tasa_transaccion_id,

          moneda_destino_id: data.pais.pais_val.split("_")[1],
        });
      }

      let valor_min_max_index = this.configuracion.costosEnvio.findIndex(
        (costo_srv) => {
          return costo_srv.pais_destino == data.pais.pais_val.split("_")[0];
        }
      );

      if (valor_min_max_index > -1) {
        await this.updateTransaccion({
          valor_minimo_origen:
            this.configuracion.costosEnvio[valor_min_max_index]
              .valor_minimo_origen,
          valor_maximo_origen:
            this.configuracion.costosEnvio[valor_min_max_index]
              .valor_maximo_origen,
        });
      }

      if (this.modoPesoToDestino) {
        await this.validarDestinoSelected(data.pais);
        await this.calculateAmount({
          tipo: "txtSimuladorOrigen",
        });
        this.validarErroresMonto();
      } else {
        if (this.primeraCarga) {
          await this.validarDestinoSelected(data.pais);
          await this.calculateAmount({
            tipo: "txtSimuladorOrigen",
          });
          this.validarErroresMonto();
        } else {
          await this.validarDestinoSelected(data.pais);
          await this.calculateAmount({
            tipo: "txtSimuladorDestino",
          });
          this.validarErroresMonto();
        }
      }
    },

    async callEnvioSeguroModal() {
      this.updateBloquearNavegacion(true);
      const modal = await modalController.create({
        component: envioSeguroHome,
      });
      modal.onDidDismiss().then(() => {
        this.updateBloquearNavegacion(false);
      });
      return modal.present();
    },

    changeInputSimuladorMandanteLocal(manda) {
      this.mandante = manda;
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      this.scroll_to_top = blur_sup_scroll(scrollTop);
      this.scroll_to_bottom = blur_inf_scroll(
        scrollTop,
        clientHeight,
        scrollHeight
      );
    },
  },
  watch: {
    valorSimuladorOrigen() {
      if (!this.primeraCarga) {
        this.validarErroresMonto();
      }
    },
    heigthScreen() {
      this.contenido_cotizador = contenido_style(
        this.heigthScreen,
        this.header,
        this.footer,
        this.plataforma == "web" ? 63 : 0
      );
    },
  },
  created() {
    if (this.transaccion.pais_destino && this.transaccion.pais_destino != "") {
      this.paisDestinoPreSelected = this.transaccion.pais_destino;
    } else {
      // console.log(this.pais_destino_inicial);
      this.paisDestinoPreSelected = this.pais_destino_inicial;
    }
  },
  mounted() {},
  async ionViewWillEnter() {
    this.descuentoExiste = false;

    this.enVista = true;
    this.updateProcesoGeneral("cotizador");

    if (this.transaccion.monto_origen) {
      // console.log("this.transaccion.monto_origen", this.transaccion.monto_origen, this.transaccion.monto_destino, this.modoPesoToDestino);
      this.valorSimuladorOrigen = this.transaccion.monto_origen;

      if (
        this.transaccion.descuento > 0 &&
        this.transaccion.tipo_descuento != ""
      ) {
        this.descuentoExiste = true;
        this.objRespCupon = {
          tipo_descuento: this.transaccion.tipo_descuento,
          porcentaje_descuento: this.transaccion.porcentaje_descuento,
          valor_descuento: this.transaccion.valor_descuento,
          id: null,
          cupon: this.transaccion.cupon,
        };

        this.descuento = this.transaccion.descuento;
        // console.log("ok", this.objRespCupon);
      }
    } else {
      this.valorSimuladorOrigen = this.monto_inicio;
    }
    // console.log(this.pais_destino_inicial);
    // this.set_pre_values_cotizador({
    //   monto_inicio: 500000,
    //   pais_destino_inicial: "604_2",
    // });
    // if (this.transaccion.pais_destino && this.transaccion.pais_destino != "") {
    //   this.paisDestinoPreSelected = this.transaccion.pais_destino;
    // } else {
    //   console.log(this.pais_destino_inicial);
    //   this.paisDestinoPreSelected = this.pais_destino_inicial;
    // }
  },
  ionViewDidEnter() {
    this.primeraCarga = false;

    if (this.desde == "beneficiarioNuevo") {
      this.updateDesde("simulador");
    } else {
      this.updateDesde("simulador");
    }

    this.$nextTick(() => {
      setTimeout(() => {
        this.header = this.$refs["myHeader_cotizador"].offsetHeight;
        this.footer = this.$refs["footer_cotizador"].offsetHeight;
        this.cont_visible = true;
        this.contenido_cotizador = contenido_style(
          this.heigthScreen,
          this.header,
          this.footer,
          this.plataforma == "web" ? 63 : 0
        );
        this.blur_sup_cotizador = blur_sup_style(this.header);
        this.blur_inf_cotizador = blur_inf_style(this.footer);
      }, 100);

      setTimeout(() => {
        if (
          this.$refs["cotizador_contenido"].scrollHeight >
          this.$refs["cotizador_contenido"].clientHeight
        ) {
          this.scroll_to_bottom = false;
        }
      }, 100);
    });
  },
  ionViewWillLeave() {
    this.enVista = false;
  },
};
</script>

<style>
.cotizadorTitle {
  color: #801b1c;
}

.cotizadorFormCont {
  margin-top: 2rem;
}

.cotizadorFormDetalle {
  margin-top: 3.125rem;
  display: flex;
  flex-direction: row;
  padding-left: 1rem;
  position: relative;
}

.cotizadorFormDetalleIcon {
  width: 1.125rem;
  height: fit-content;
}

.cotizadorFormDetalleIconImg {
  width: 1.5rem;
  position: relative;
  height: 2.5rem;
  border-left: 2px solid #787878;
}

.cotizadorFormDetalleIconImgUltimo {
  width: 1.38rem;
  position: relative;
  height: 2.5rem;
}

.cotizadorFormDetalleIcon img {
  position: absolute;
  width: 100%;
  top: 0;
  left: -0.75rem;
}

.cotizadorFormDetalleIconImgUltimo img {
  position: absolute;
  width: 100%;
  top: 0;
  left: -0.68rem;
}

.cotizadorFormDetalle_der {
  display: flex;
  flex-direction: column;
  position: relative;

  width: 100%;
}

.cotizadorFormDetalle_der_row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  height: 2.5rem;
  padding-top: 0.2rem;
}

.cotizadorFormDetalleMonto {
  position: relative;
  font-family: "Roboto";
  font-style: normal;
  /* font-weight: 700; */
  font-size: 1rem;
  line-height: 1.1875rem;
  letter-spacing: 1px;
  padding-left: 0.3rem;
  width: fit-content;
}

.cotizadorFormDetalleText_gris {
  color: #787878;
}

.cotizadorFormDetalleMonto_lila {
  color: #614ad3;
}

.cotizadorFormDetalleMontoRow {
  position: relative;
}

.cotizadorFormDetalleMontoRowBtnCuponDesc {
  height: 1.5625rem;
  background: #614ad3;
  border-radius: 5px;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 0.7rem;
  line-height: 0.9rem;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: -4px;
  left: 0;
  width: 7.5rem;
  cursor: pointer;
}

.cotizadorFormDetalleTextContainer {
  position: relative;
  margin-right: 0;
  margin-left: auto;
  right: 0;
  left: auto;
  padding-top: 0.2rem;
}

.cotizadorFormDetalleText {
  letter-spacing: 1px;
  font-family: "Roboto";
  font-style: italic;
  font-weight: 500;
  font-size: 0.775rem;
  line-height: 0.9rem;
  width: 10.5rem;
  text-align: right;

  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cotizadorFormHasta {
  padding-top: 0.5rem;
}

.cotizadorText {
  display: flex;
  justify-content: center;
}

.cotizadorTextSpan1 {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.5px;
  color: #614ad3;
  padding-left: 1rem;
}

.cotizadorTextSpan2 {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 900;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.5px;
  color: #614ad3;
}

.cotizadorBtn_container {
  padding-top: 2.5rem;
}

.cotizadorBtn {
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
  margin-top: 1rem;
}

.cotizadorEtapas {
  margin-top: 1rem;
  width: 100%;
  text-align: center;
  padding-bottom: 0.3rem;
}

.cotizadorFormDetalleTextRowDescuento {
  /* display: flex;
  justify-content: space-between;
  position: relative; */
  padding-right: 1.5rem;
  margin-top: -3px;
  /* white-space: nowrap; */
}

.cotizadorFormDetalleTextRowDescuentoBtn {
  margin-left: 0.3rem;
  padding-left: 0.1rem;

  border-radius: 3px;
  border: 1px solid var(--customF-C4C4C4);
  color: #ffffff;
  width: 1.15rem;
  height: 1.05rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
}

.cotizadorFormDetalleTextRowDescuentoBtn img {
  width: 0.7rem;
  height: 0.7rem;
}

.cotizador_head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cotizador_head img {
  width: 2rem;
}

.cotizador_head_t {
  width: 65%;
}

.cotizador_head_i {
  width: 35%;
}

.cotizador_head_cambiar_texto {
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
}

.cotizadorFormOrden {
  display: flex;
  flex-direction: column;
}




/* ////////////////-------------------------------------/////////// */
@media (min-width: 450px) {
  .cotizadorFormDetalleText {
    font-size: 0.875rem;
  line-height: 1rem;
  }
}
</style>