<template>
  <div class="inputSimCont">
    <div class="inputSimMontoLabel text_basico f_w_500">{{ label }}</div>
    <div class="inputSimRowCont">
      <div
        class="inputSimMontoCaja"
        :class="
          stateInput == 'inicio'
            ? id_listaPais == 'desde'
              ? 'inputSimMontoInicioDesde'
              : 'inputSimMontoInicio'
            : stateInput == 'onFocus'
            ? 'inputSimMontoOnFocus'
            : stateInput == 'post'
            ? 'inputSimMontoPost'
            : stateInput == 'postMandante'
            ? 'inputSimMontoPostMandante'
            : stateInput == 'onError'
            ? 'inputSimMontoOnError'
            : ''
        "
      >
        <input
          type="tel"
          class="input"
          :class="modoPesoToDestino ? 'destacado' : 'normal'"
          v-model="valorLocal"
          @keypress="isNumber($event)"
          @keyup="calculateAmountLocal($event)"
          autocomplete=""
          :disabled="!editable"
          ref="focusMe"
          @focus="
            setFocusLocal($event);
            cambiarType($event);
          "
          @blur="cambiarType($event)"
        />
        <!-- :id="dynamicID()" -->
      </div>
      <div class="inputSimRowContSeparador"></div>
      <div class="inputSimPaisCont" @click.stop="">
        <selectPaisMoneda
          :data="lista"
          mensajeError=""
          :error="false"
          :tipoSelect="id_listaPais"
          @selectPaisMonedaSelected="selectPaisMonedaSelectedLocal"
          :selectSelectedType="selectSelectedType"
          @dispatchChangeData="paisSelectedLocal"
          :paisDestinoPreSelected="paisDestinoPreSelected"
        />
      </div>
    </div>

    <div class="inputSimMontoErrorCont">{{ msgError }}</div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { mapState } from "vuex";
import selectPaisMoneda from "./selectPaisMoneda.vue";

export default {
  setup() {
    const { t, locale } = useI18n();
    return { t, locale };
  },
  name: "inputSimulador",
  props: {
    modoPesoToDestino: { type: Boolean },
    valor: { type: Number },
    simbolMoney: { type: String },
    tipo: { type: String },
    label: { type: String },
    editable: { type: Boolean },
    lista: { type: Array },
    cerrarListaPais: { type: Boolean },
    id_listaPais: { type: String },
    otraListaPaisCerrar: { type: String },
    selectSelectedType: String,
    mandante: String,
    paisDestinoPreSelected: String,
    error: Boolean,
    msgError: String,
  },
  components: {
    selectPaisMoneda,
  },
  data() {
    return {
      valorLocal: "50000",
      delay: null,
      stateInput: "inicio",
      inFocus: false,
    };
  },
  computed: {
    ...mapState(["i18n_locales", "transaccion"]),
  },
  methods: {
    selectPaisMonedaSelectedLocal(tipoSelectSelected) {
      this.$emit("selectSelected", tipoSelectSelected);
    },
    cerrarOtraListaPaisLocal(origen) {
      this.$emit("cerrarOtraListaPais", origen);
    },

    calculateAmountLocal(e) {

      var evt = e || window.event;
      // console.log(evt.keyCode);
      if (evt.keyCode === 9) {
        evt.preventDefault();
        return;
      }
      if (this.delay) {
        clearTimeout(this.delay);
      }

      this.delay = setTimeout(() => {
        let cantidad = this.valorLocal;

        this.$emit("calculateAmountL", {
          tipo: this.tipo,
          valor: cantidad,
        });
      }, 700);
    },

    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;

      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        // charCode != this.i18n_locales[this.locale].miles_cod &&
        charCode != this.i18n_locales[this.locale].deci_cod
      ) {
        evt.preventDefault();
      } else if (
        charCode == this.i18n_locales[this.locale].deci_cod &&
        this.transaccion.moneda_destino_id != "2"
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    paisSelectedLocal(data) {
      this.$emit("paisSelected", data);
    },
    setFocusLocal(e) {
      var evt = e || window.event;
      if (evt.keyCode === 9 || typeof evt.keyCode == "undefined") {
        evt.preventDefault();
        return;
      }
      this.$emit("setFocus", this.id_listaPais != "desde" ? 2 : 1);
    },

    async cambiarType(evt) {
      let evento = evt.type;

      if (evento == "focus") {
        this.stateInput = "onFocus";
        this.inFocus = true;
        this.$emit("changeInputSimuladorMandante", this.id_listaPais);
      } else if (evento == "blur") {
        this.inFocus = false;
        if (this.error) {
          this.stateInput = "onError";
        } else {
          if (this.mandante == this.id_listaPais) {
            this.stateInput = "postMandante";
          } else {
            this.stateInput = "post";
          }
        }
      }

      this.interactuado = true;
    },
  },
  watch: {
    valor() {
      this.valorLocal = this.valor;
    },
    mandante() {
      if (this.mandante != this.id_listaPais) {
        if (this.error) {
          this.stateInput = "onError";
        } else {
          this.stateInput = "post";
        }
      }
    },
    error() {
      if (this.error) {
        this.stateInput = "onError";
      } else {
        if (this.mandante == this.id_listaPais) {
          this.stateInput = "postMandante";
        } else {
          this.stateInput = "post";
        }
      }
    },
  },
  mounted() {
    this.valorLocal = this.valor;
  },
};
</script>

<style>
.inputSimCont {
  width: 100%;
}
.inputSimMontoLabel {
  display: flex;
  align-items: flex-end;
  color: #787878;
}
.inputSimRowCont {
  display: flex;
  justify-content: space-between;
}

.inputSimMontoCaja {
  width: 65%;

  border-radius: 5px;
  display: flex;
  justify-content: center;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.3125rem;
  letter-spacing: 0.5px;
}
.inputSimMontoInicioDesde {
  border: 1.5px solid #787878;
  color: #614ad3;
  background-color: #ffffff;
}
.inputSimMontoInicio {
  border: 1px solid #787878;
  color: #787878;
  background: rgba(225, 225, 225, 0.4);
}
.inputSimMontoOnFocus {
  border: 2px solid #614ad3;
  color: #614ad3;
  background-color: #ffffff;
}
.inputSimMontoPost {
  border: 1.5px solid #787878;
  color: #787878;
  background: rgba(225, 225, 225, 0.4);
}
.inputSimMontoPostMandante {
  border: 1.5px solid #614ad3;
  color: #614ad3;
  background-color: #ffffff;
}
.inputSimMontoOnError {
  border: 1.5px solid #787878;
  color: var(--customA-801B1C);
  background-color: #ffffff;
}

.inputSimMontoCaja .input {
  border: none;
  background-color: #ffffff;
  width: 90%;
}
.inputSimMontoCaja .input:focus {
  outline: none;
}
.inputSimRowContSeparador {
  width: 1%;
}
.inputSimPaisCont {
  width: 34%;
  background: #801b1c;
  box-shadow: 4.28517px 4.28517px 8.57033px rgba(196, 196, 196, 0.3);
  border-radius: 5px;
}

.inputSimMontoErrorCont {
  height: 10px;
  font-family: "Roboto";
  font-style: italic;
  font-weight: 500;
  font-size: 0.6rem;
  line-height: 0.7rem;
  color: #801b1c;
}
</style>