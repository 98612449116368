<template>
  <div
    class="selectPaisMoneda"
    @blur="cambiarType($event)"
    @focus="cambiarType($event)"
  >
    <div class="selectPaisMonedaCaja">
      <div
        class="selectPaisMonedaVisor"
        :class="cambioEstado ? 'active' : ''"
        @click.stop="
          tipoSelect != 'desde' ? (cambioEstado = !cambioEstado) : false
        "
      >
        <div class="selectPaisMonedaVisorText">
          <div class="selectPaisMonedaVisorTextSimbol">
            {{ itemSelected.sigla }}
          </div>
          <div class="selectPaisMonedaVisorTextPaisNom">
            {{ itemSelected.pais_nom }}
          </div>
        </div>
        <div class="selectPaisMonedaVisorSelect">
          <img :src="iconChevronWhite" alt="" v-show="tipoSelect != 'desde'" />
        </div>
      </div>

      <div
        class="selectPaisMonedaopciones"
        :class="cambioEstado ? 'active' : ''"
      >
        <div class="selectPaisMonedaopcionesCont">
          <div
            @click="item.activo == 1 ? setCambioSelect(item) : false"
            v-for="(item, index) in listaDesplegar"
            :key="index"
          >
            <div
              :class="
                item.activo == 1
                  ? 'selectPaisMonedaopcion'
                  : 'selectPaisMonedaopcionNoHabil'
              "
            >
              <div class="selectPaisMonedaopcionPais">{{ item.pais_nom }}</div>
              <div class="selectPaisMonedaopcionMoneda">{{ item.sigla }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mensajesForm">
      <div class="mensajeError">
        <span v-show="stateInput == 'onError'">{{ mensajeError }}</span>
      </div>
      <div class="mensajeOkFocus">&nbsp;</div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
  props: {
    data: { type: Array },
    placeholder: { type: String },
    mensajeError: { type: String },
    error: { type: Boolean },
    tipoSelect: String,
    selectSelectedType: String,
    paisDestinoPreSelected: String,
  },
  data() {
    return {
      iconChevronWhite: require("../../assets/imgs/iconChevronWhite.svg"),
      itemSelected: {},
      cambioEstado: false,
      stateInput: "inicio",
      inFocus: false,
      listaDesplegar: [],
    };
  },
  computed: {
    ...mapState(["focusGlobal"]),
  },
  methods: {
    ...mapMutations(["updateFocus"]),

    setCambioSelect(item) {
      this.cambioEstado = !this.cambioEstado;
      this.itemSelected = item;
    },
    cambiarType(evt) {
      let evento = evt.type;
      if (evento == "focus") {
        this.stateInput = "onFocus";
        this.inFocus = true;
      } else if (evento == "blur") {
        this.stateInput = "post";
        this.inFocus = false;
      }
    },
  },
  watch: {
    data() {
      this.itemSelected = {};
    },
    error() {
      if (this.error) {
        this.stateInput = "onError";
      } else {
        if (this.inFocus) {
          this.stateInput = "onFocus";
        } else {
          this.stateInput = "post";
        }
      }
    },
    itemSelected() {
      if (this.itemSelected) {
        this.$emit("dispatchChangeData", {
          pais: {
            pais_val: this.itemSelected.pais_val,
            nameBanderas: this.itemSelected.pais_nom,
          },
          origen:
            this.tipoSelect != "desde"
              ? "txtSimuladorDestino"
              : "txtSimuladorOrigen",
        });
      }
    },
    focusGlobal() {
      if (this.focusGlobal) this.cambioEstado = false;
    },
    cambioEstado() {
      if (this.cambioEstado) {
        this.updateFocus(false);
        this.$emit("selectPaisMonedaSelected", this.tipoSelect);
      }
    },
    selectSelectedType() {
      if (this.selectSelectedType != this.tipoSelect) {
        this.cambioEstado = false;
      }
    },
    paisDestinoPreSelected() {
      if (this.paisDestinoPreSelected != "") {
        let aux = this.listaDesplegar.findIndex((pais) => {
          return pais.pais_val == this.paisDestinoPreSelected;
        });

        if (aux > -1) {
          this.itemSelected = this.listaDesplegar[aux];
        }
      }
    },
  },
  mounted() {
    
    if (this.tipoSelect == "desde") {
      this.listaDesplegar = [
        {
          activo: "1",
          color: "",
          disable: false,
          pais_nom: "Chile",
          pais_val: "152",
          pausa: "0",
          sigla: "CLP",
          tiempo_entrega: "",
        },
      ];
      this.itemSelected = this.listaDesplegar[0];
    } else {
      this.listaDesplegar = [...this.data];
      if (this.paisDestinoPreSelected != "") {
        let aux = this.listaDesplegar.findIndex((pais) => {
          return pais.pais_val == this.paisDestinoPreSelected;
        });

        if (aux > -1) {
          // this.setCambioSelect(this.listaDesplegar[aux]);
          this.itemSelected = this.listaDesplegar[aux];
        }
      } else {
        
        let i = this.listaDesplegar.findIndex((item) => {
          return item.activo == "1";
        });
        if (i != -1) this.itemSelected = this.listaDesplegar[i];
      }
    }
  },
};
</script>

<style scoped>
.selectPaisMoneda {
  width: 100%;
  height: 100%;
  margin: auto;
}

.selectPaisMonedaCaja {
  width: 100%;
  margin: auto;
  position: relative;
  border-radius: 5px;
  height: 100%;
}

.selectPaisMonedaVisor {
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.2s ease all;
  padding: 0.2rem;
  position: relative;
  z-index: 100;
  height: 100%;
}

.selectPaisMonedaVisor.active img {
  transform: rotate(180deg);
  transition: transform 0.2s ease-in-out;
}

.selectPaisMonedaVisor.active:before {
  content: "";
  display: block;
  height: 0;
  width: 0;
  position: absolute;
  bottom: -30px;
}

.selectPaisMonedaopciones {
  background: #fff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  z-index: 300;
  width: 9.1rem;
  display: none;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  position: absolute;
  margin-top: -2rem;
  right: 0;
  left: auto;
}
.selectPaisMonedaopcionesCont {
  background-color: #fff;
  overflow-y: scroll;
  max-height: 250px;
  padding-right: 10px;
}
.selectPaisMonedaopcionesCont > div {
  border-bottom: 1px solid #ececec;
  width: 100%;
  padding-bottom: 0.2rem;
  padding-top: 0.3rem;
}

.selectPaisMonedaopcionesCont::-webkit-scrollbar {
  width: 4px;
  height: 90%;
  max-height: 90%;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  padding-left: 10px;
}

.selectPaisMonedaopcionesCont::-webkit-scrollbar-track {
  background-color: var(--customF-C4C4C4);
  border-radius: 10px;
}

.selectPaisMonedaopcionesCont::-webkit-scrollbar-thumb {
  background-color: var(--customA-801B1C);
  border-radius: 10px;
  height: 1rem;
  max-height: 1rem;
}

.selectPaisMonedaopciones.active {
  display: block;
  animation: fadeIn 0.3s forwards;
}

@keyframes fadeIn {
  from {
    transform: translateY(-20px) scale(0.5);
  }
  to {
    transform: translateY(0) scale(1);
  }
}

.selectPaisMonedaopcion {
  width: 100%;
  transition: 0.2s ease all;
  cursor: pointer;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: #787878;
  display: flex;
  justify-content: space-between;
}
.selectPaisMonedaopcionNoHabil {
  width: 100%;
  transition: 0.2s ease all;
  cursor: pointer;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  justify-content: space-between;
  color: #c4c4c4;
}

.selectPaisMonedaopcionMoneda {
  padding-left: 0.5rem;
}

.selectPaisMonedaVisorText {
  padding-left: 0.3rem;
}
.selectPaisMonedaVisorTextSimbol {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.5px;
  color: #ffffff;
}
.selectPaisMonedaVisorTextPaisNom {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  font-size: 8px;
  line-height: 9px;
  letter-spacing: 0.5px;
  color: #ffffff;
}

.selectPaisMonedaVisorSelect {
  padding-right: 0.5rem;
}

@media screen and (max-width: 800px) {
  .selectbox {
    width: 100%;
  }
}
</style>